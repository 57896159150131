const strainFilterReducer = (state, { action, value }) => {
  switch (action) {
    case "setMarket":
      return {
        ...state,
        filtersChanged: true,
        market: value,
        highlightedStrain: null,
      }

    case "toggleTypeFilter":
      return {
        ...state,
        filtersChanged: true,
        typeFilters: togglePresence(state.typeFilters, value),
        highlightedStrain: null,
      }

    case "toggleTerpeneFilter":
      return {
        ...state,
        filtersChanged: true,
        terpeneFilters: togglePresence(state.terpeneFilters, value),
        highlightedStrain: null,
      }

    case "clearFilters":
      return {
        ...state,
        typeFilters: new Set(),
        terpeneFilters: new Set(),
        highlightedStrain: null,
      }

    case "highlightStrain":
      return {
        ...state,
        highlightedStrain: value,
      }

    case "clearHighlight":
      return {
        ...state,
        highlightedStrain: null,
      }

    case "replaceState":
      return {
        ...state,
        ...value,
      }

    default:
      return state
  }
}

export default strainFilterReducer

const togglePresence = (filterSet, value) => {
  const filters = new Set(filterSet)

  if (filters.has(value)) {
    filters.delete(value)
  } else {
    filters.add(value)
  }

  return filters
}
